import {getters} from './getters'
import {actions} from './actions'
import {mutations} from './mutations'

function createNewState(state) {
    state.loginSuccess = false;
    state.loginError = false;
    state.isLoading = false;
    state.tokenData = null;
    state.userName = null;
    state.userPass = null;
    state.PriceComparisonData = null;
    state.dwc = [];
    state.headerMenuData = [];
    state.enrolmentData = null;
    state.allowedProducts = null;
    state.resourcesFilterOptions = null;
    state.APIError = null;
    state.InvalidDWCPUrlMessage = null;
    state.SessionExpired = null;
    state.Products = null;
    state.API_Call_Flag = true;
    state.forgotPass = null;
    state.addonDetails = null;
    state.oems = [];
    state.dealerProductDetails = null;
    state.selectedDealerBudgetDetails = null;
    state.selectedDealerLeadSettingsDetails = null;
    state.adjustCoverageLeadSettingsDetails = null;
    state.radiusCityDetails = null;
    state.cityZipListDetails = null;
    state.saveDealersLeadCoverage = null;
    state.preferencesLeadSettingsDetails = null;
    state.VehicleManagementDetails = null;
    state.updateSuccessAPIResp = null;
    state.UserProfileDetails = null;
    state.UserProfileContactDetails = null;
    state.UserProfileErrors = null;
    state.BillingProfileDetails = null;
    state.updateErrorAPIResp = null;
    state.logViewerGetPageData = null;
    state.logViewerSearchData = null;
    state.MWSearchResult = null;
    state.logViewerFirstSubscribeDate = null;
    state.payloadLookupGetPageData = null;
    state.payloadLookupSearchData = null;
    state.payloadFailureDetails = null;
    state.payloadLookupPayloadXMLData = null;
    state.manageEnrolmentFilterOptions = null;
    state.manageEnrolmentDownloadManagerData = null;
    state.SearchManageEnrolmentData = null;
    state.ProgramIdsData = null;
    state.AddonsIdsData = null;
    state.ViewLeadsFilterOptions = null;
    state.ViewLeadsSearchData = null;
    state.ChangePasswordPermission = null;
    state.ChangePasswordResp = null;
    state.ChangePasswordRespError = null;
    state.GenerateReportDetails = null;
    state.GenerateReportError = null;
    state.ReportFilterDetails = null;
    state.ReportFormDetails = null;
    state.enrollmentDetails = null;
    state.subscriptionDetails = null;
    state.EULAOptions = null;
    state.enrollmentOptions = null;
    state.ChangeSubscriptionsDetail = null;
    state.DealerEnrollmentData = null;
    state.PostSetupDealerEnrollmentData = null;
    state.RequestCreditReason = null;
    state.AccountLocked = null;
    state.EnrollmentDealerDetails = null;
    state.ComplianceDataRecords = null;
    state.ComplianceDataReportDetails = null;
    state.ComplianceAggregatedReportDetails = null;
    state.CrmComplianceAggregatedReportDetails = null;
    state.CrmNonComplaintReportDetails = null;
    state.ComplianceDealerReportDetails = null;
    state.ComplianceNavigationToAggregate = false;
    state.ComplianceNavigationToSearch = false;
    state.AddonSetupDealerDetails = null;
    state.AddonCustomParameterDetails = null;
    state.AddonCustomParameterToCopy = null;
    state.AddonProgramConfirmationDetails = null;
    state.AddonModelDetails = null;
    state.AddonParamResponse = null;
    state.NonComplaintReportDetails = null;
    state.ProductPricingDetails = null;
    state.AffinityDealerSalesReport = null;
    state.ZipCodeBulkImportResult = null;
    state.ComplianceAuditScoresDataRecords = null;
}

let state = {};
state.UserDetails = null;
createNewState(state);


const namespaced = true;

export const dwc = {
    namespaced,
    state,
    mutations,
    actions,
    getters
};

export const resetState = () => {
    createNewState(state);
};
