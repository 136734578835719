import axios from 'axios'
import {store} from '../store'
import auth from "../auth/authService";

const AXIOS = axios.create({
    baseURL: '/',
    timeout: 120000
});
const AXIOS_Export = axios.create({
    baseURL: '/',
    timeout: 120000
});
// before a request is made start the nprogress
AXIOS.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
    };
    return config;
});
// before a response is returned stop nprogress
AXIOS.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});

AXIOS_Export.interceptors.request.use(config => {
    store.dispatch('dwc/setLoading', true);
    config.headers.common = {
        'Authorization': `Bearer ${auth.getAccessToken()}`,
        // 'content-type': 'application/x-www-form-urlencoded'
    };
    return config;
});
AXIOS_Export.interceptors.response.use(response => {
    store.dispatch('dwc/setLoading', false);
    return response;
});


export const initComplianceInterceptors = () => {
    auth.addInterceptors(AXIOS);
    auth.addInterceptors(AXIOS_Export);
};

export const generateComplianceDataRecordAPI = (data) =>
    new Promise((resolve, reject) => {
        const urlParms = data.sort ? `page=${data.page}&sort=${data.sort}` : `page=${data.page}`;
        AXIOS.post(`/api/portal/compliance/report/search?${urlParms}`,
            data.requestPayload,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const generateComplianceScoresReportAPI = (data) =>
    new Promise((resolve, reject) => {
        const urlParms = data.sort ? `page=${data.page}&sort=${data.sort}` : `page=${data.page}`;
        AXIOS.post(`/api/portal/compliance/scoresReport/search?${urlParms}`,
            data.requestPayload,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const prepareCreateReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/report/prepareCreate/${data.dealerCode}/${data.auditType}`,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const prepareCreateCrmComplianceReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/report/prepareCrmComplianceCreate/${data.auditType}/${data.oemId}`,
            data.requestPayload,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const createNewReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/report/create`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getOEMRegionsDetailsAPI = (oemId) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/regions?oemId=${oemId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getRegionsDistrictsDetailsAPI = (regionId) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/districts?regionId=${regionId}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const updateViewedReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/report/update`,
            data,
            {
                headers: {'content-type': 'application/json'}
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const uploadFilesAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/report/uploadFile/${data.reportId}`,
            data.formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const zipCodeBulkImportSaveAPI = (dealerCode) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/leadProgramSettings/zipCodeBulkImportSave/${dealerCode}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const zipCodeBulkImportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/leadProgramSettings/zipCodeBulkImport/${data.dealerCode}`,
            data.form,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getZipCodeBulkImportResultAPI = (dealerCode) =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/leadProgramSettings/zipCodeBulkImportResult/${dealerCode}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const removeUploadFileAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/report/removeFile/${data.reportId}?uuid=${data.uuid}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const complianceDealerReportAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/generate/ComplianceDealerReport`, data, {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getComplianceOEMsAPI = () =>
    new Promise((resolve, reject) => {
        AXIOS.get(`/api/portal/compliance/oems`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error)
            });
    });
export const getComplianceDealerReportDetailsAPI = (data) =>
    new Promise((resolve, reject) => {

        const reportId = data.reportId;
        const mandatory = data.mandatory;
        const qualified = data.qualified;

        AXIOS.get(`/api/portal/compliance/report/view/${reportId}?mandatory=${mandatory}&qualified=${qualified}`)
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getComplianceAggregateReportDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        const urlParms = data.sort ? `page=${data.page}&sort=${data.sort}` : `page=${data.page}`;
        AXIOS.post(`/api/portal/compliance/generate/ComplianceAggregateReport?${urlParms}`, data, {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getCrmComplianceAggregateReportDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        const urlParms = data.sort ? `page=${data.page}&sort=${data.sort}` : `page=${data.page}`;
        AXIOS.post(`/api/portal/compliance/generate/CrmComplianceAggregateReport?${urlParms}`, data, {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getCrmNonCompliantReportDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        const urlParms = data.sort ? `page=${data.page}&sort=${data.sort}` : `page=${data.page}`;
        AXIOS.post(`/api/portal/compliance/generate/CRMNonCompliantReport?${urlParms}`, data, {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
export const getNonCompliantReportDetailsAPI = (data) =>
    new Promise((resolve, reject) => {
        const urlParms = data.sort ? `page=${data.page}&sort=${data.sort}` : `page=${data.page}`;
        AXIOS.post(`/api/portal/compliance/generate/DWCPWebproviderNonCompliantReport?${urlParms}`, data, {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });

export const getDealerReportFilterAPI = (data) =>
    new Promise((resolve, reject) => {
        AXIOS.post(`/api/portal/compliance/dealerReportFilter`, data, {headers: {'content-type': 'application/json'}})
            .then(response => resolve(response))
            .catch(error => {
                store.dispatch('dwc/setLoading', false);
                reject(error);
            })
    });
